import React, { useEffect } from "react"
import { Layout } from "@/layout"
import { Box, SEO, Heading, Text } from "@/components"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import localStorage from "@/utils/localStorage"
import { navigate } from "gatsby"
import $ from "jquery"

export default function Application() {
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  let referrerId = localStorage.get("referrer_id")
  let refParam = urlParameters.query?.referrer_id
  if (referrerId && !refParam) {
    navigate(`/freetraining-fb/application?referrer_id=` + referrerId)
  }

  // useEffect(() => {
  //   window.jQuery = window.jQuery || (() => ({}))
  //   if (window.hbspt) {
  //     window.hbspt.forms.create({
  //       portalId: "6200944",
  //       formId: "7b8d94fc-dd09-4340-9ff3-92304fddc42f",
  //       target: "#hubspotForm",
  //       onFormSubmitted: function () {
  //         window.location.href = "/schedule"
  //       },
  //     })
  //   }
  // }, [])

  useEffect(() => {
    window.jQuery = $
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "6200944",
        formId: "20a7c86e-bf0b-45f4-a2d5-f695ec81d536",
        target: "#hubspotForm",
        onFormSubmit: function ($form) {
          console.log("ON FORM SUBMITTTT", $form)
          var level = $form.find(
            'select[name="where_would_you_rate_your_guitar_playing_"]'
          )[0].value
          var playingTime = $form.find(
            'select[name="how_long_have_you_played_the_guitar"]'
          )[0].value

          var doesntHaveResources = $form.find(
            'input[name="the_guitar_mastery_intensive_is_a_significant_financial_investment_in_your_growth_as_a_musician_are"]:checked'
          )[0].value
          console.log(doesntHaveResources, level, playingTime)
          if (
            level ==
            "Beginner" || playingTime == "< 6 months"
          ) {
            window.localStorage.setItem("redirectUrl", "/beginner-path")
          } else if (
            doesntHaveResources ==
            "I don't have any additional finances to invest right now."
          ) {
            window.localStorage.setItem("redirectUrl", "/application-submitted")
          } else {
            window.localStorage.setItem("redirectUrl", "/free-training-fb/schedule")
          }
        },
        onFormSubmitted: function () {
          console.log("USING THIS")
          var url = window.localStorage.getItem("redirectUrl")
          window.location.href = url
        },
      })
    }
  }, [])

  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Application Form"
        description="Students are selected based on the quality of their application/interview and application date."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box width={["100%", null, null, 5 / 12]} textAlign="center" mx="auto">
          <Text variant="subhead" mt="4rem">
            STEP 1 of 3
          </Text>
          <Heading
            level="1"
            mb="1rem"
            mt="3rem"
            px={["2rem", "2rem", "2rem", "2rem"]}
          >
            Application Form
          </Heading>
          <Text mb="1rem" mt="1rem" px={["2rem", "2rem", "2rem", "2rem"]}>
            Students are selected based on the quality of their
            application/interview and application date.
          </Text>
          <Text>
            <strong>First come, first served.</strong>
          </Text>
        </Box>
        <Box width={["90%", null, null, 6 / 12]} mx="auto" mt="3rem">
          <div id="hubspotForm"></div>
        </Box>
      </Box>
    </Layout>
  )
}
